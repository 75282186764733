import React, { useMemo } from "react"
// import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "@components/Layout"
import TransitionLink from "../components/TransitionLink"

import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import client from "../components/appolloClient"; 

// Define your GraphQL query
const Story = gql`
  query MyQuery {
    entries(slug: "stories") {
      ... on storiesIndex_storiesIndex_Entry {
        id
        storyCollections {
          ... on storyCollections_story_BlockType {
            id
            entry {
              uri
              title
            }
            comingSoon
            featureImage {
              ... on uploads_Asset {
                id
                url
                title
                hasFocalPoint
              }
            }
          }
        }
      }
      seomatic {
        metaTitleContainer
        metaTagContainer
        metaLinkContainer
        metaScriptContainer
        metaJsonLdContainer
        metaSiteVarsContainer
        frontendTemplateContainer
      }
    }
  }
`;

const StoriesPage = () => {

  const { loading, error, data } = useQuery(Story, { client });

  if (loading) {
    return <p>.</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const { seomatic, storyCollections } = data?.entries[0]


  /* Story block component: either a link or static image */
  const StoryBlock = ({ image, comingSoon, title, eager }) => (
    <>
      <GatsbyImage
        image={image}
        className={`w-full h-full ${comingSoon ? "image--greyscale" : ""}`}
        loading={eager ? `eager` : `lazy`}
        alt=""
      />
      <div className="flex absolute top-1/2 left-1/2 lg:text-2xl md:text-xl text-2xl uppercase">
        <div className="flex-1 stories">{title ?? ""}</div>
        {comingSoon && <div className="stories text-center">Coming Soon</div>}
      </div>
    </>
  );

  return (

    <Layout showNav={true} seomatic={seomatic}>
      <div className="container mx-auto overflow-hidden md:pt-48 pt-24">
        <div className="md:px-0 px-6">
          <div className="flex items-center justify-center flex-wrap">
            {/* Loop and render each story */}
            {storyCollections.length > 0 &&
              storyCollections.map(({ featureImage, entry, comingSoon }, i) => {
                const [story] = entry
                const [img] = featureImage

                return (
                  <div
                    key={i}
                    className={`md:w-1/3 w-full mb-6 relative ${
                      comingSoon ? "text-grey" : ""
                    }`}
                    style={{height:"250px"}}
                  >
                    {/* Link wrapper */}
                    {!comingSoon && (
                      <TransitionLink
                        key={i}
                        to={`/${story && story.uri ? story.uri : ``}`}
                      >
                        <img
                          src={img?.url}
                          className={`w-full h-full ${
                            comingSoon ? "image--greyscale" : ""
                          }`}
                          loading={i > 2 ? `eager` : `lazy`}
                          alt=""
                        />
                        <div className="flex absolute top-1/2 left-1/2 lg:text-2xl md:text-xl text-2xl uppercase">
                          <div className="flex-1 stories">{story?.title ?? ``}</div>
                          {comingSoon && <div className="stories text-center">Coming Soon</div>}
                        </div>
                      </TransitionLink>
                    )}
                
                    {/* Static image */}
                    {comingSoon && (
                      <><img
                        src={img?.url}
                        className={`w-full h-full ${comingSoon ? "image--greyscale" : ""}`}
                        loading={i > 2 ? `eager` : `lazy`}
                        alt="" /><div className="flex absolute top-1/2 left-1/2 lg:text-2xl md:text-xl text-2xl uppercase">
                          <div className="flex-1 stories">{story?.title ?? ``}</div>
                          {comingSoon && <div className="stories text-center">Coming Soon</div>}
                        </div></>
                    )}
                  </div>
                );
                
              })}
          </div>
        </div>
      </div>
    </Layout>
  )
}


export default StoriesPage
